import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import {MatIconEnum} from '../models/common/mat-icon.enum';

@Injectable()
export class RegisterIconService {

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
  ) {}



  registerIcons(): void {
    this.registerIcon(MatIconEnum.LOGOUT, '../../../../assets/images/logout.svg');

    this.registerIcon(MatIconEnum.PENCIL, '../../../../assets/images/pencil.svg');
    this.registerIcon(MatIconEnum.BIN, '../../../../assets/images/waste-bin.svg');
    this.registerIcon(MatIconEnum.INFINITY, '../../../../assets/images/infinity.svg');

    this.registerIcon(MatIconEnum.PLAY, '../../../../assets/images/play.svg');
    this.registerIcon(MatIconEnum.PAUSE, '../../../../assets/images/pause.svg');
    this.registerIcon(MatIconEnum.DOWNLOAD, '../../../../assets/images/download.svg');

    this.registerIcon(MatIconEnum.CROSS, '../../../../assets/images/cross-gray.svg');

    this.registerIcon(MatIconEnum.LOCKED, '../../../../assets/images/locked.svg');
    this.registerIcon(MatIconEnum.UNLOCKED, '../../../../assets/images/unlocked.svg');

    this.registerIcon(MatIconEnum.DRAG_AND_DROP, '../../../../assets/images/drag_drop.svg');

    this.registerIcon(MatIconEnum.DASHBOARD, '../../../../assets/images/dashboard.svg');
    this.registerIcon(MatIconEnum.ORDERS, '../../../../assets/images/orders.svg');
    this.registerIcon(MatIconEnum.PRODUCTS, '../../../../assets/images/storage.svg');
    this.registerIcon(MatIconEnum.USERS, '../../../../assets/images/users-1.svg');
    this.registerIcon(MatIconEnum.PROFILE, '../../../../assets/images/user.svg');
    this.registerIcon(MatIconEnum.EMBED_CODE, '../../../../assets/images/embed-code.svg');

    this.registerIcon(MatIconEnum.EPALLET_LOGO, '../../../../assets/images/epallet-logo-icon.svg');
    this.registerIcon(MatIconEnum.EPALLET_BLACK_LOGO, '../../../../assets/images/epallet-logo-icon-black.svg');
    this.registerIcon(MatIconEnum.EPALLET, '../../../../assets/images/epallet-new-logo.svg');

    this.registerIcon(MatIconEnum.RESTORE, '../../../../assets/images/restore.svg');

    this.registerIcon(MatIconEnum.EYE, '../../../../assets/images/eye.svg');

    this.registerIcon(MatIconEnum.ELLIPSIS, '../../../../assets/images/ellipsis.svg');

    this.registerIcon(MatIconEnum.DELETE, '../../../../assets/images/delete.svg');

    this.registerIcon(MatIconEnum.ARROW_RIGHT, '../../../../assets/images/arrow-right-big.svg');
    this.registerIcon(MatIconEnum.ARROW_RIGHT_SMALL, '../../../../assets/images/arrow-right-small.svg');

    this.registerIcon(MatIconEnum.INFORMATION, '../../../../assets/images/information.svg');

    this.registerIcon(MatIconEnum.TICK, '../../../../assets/images/tick.svg');

    this.registerIcon(MatIconEnum.ADD, '../../../../assets/images/add.svg');
    this.registerIcon(MatIconEnum.REMOVE, '../../../../assets/images/remove.svg');

    this.registerIcon(MatIconEnum.SERVICES, '../../../../assets/images/headphones.svg');

    this.registerIcon(MatIconEnum.CHANGE_LOG, '../../../../assets/images/change_log.svg');

    this.registerIcon(MatIconEnum.FILTER, '../../../../assets/images/filter.svg');

    this.registerIcon(MatIconEnum.ADD_USER, '../../../../assets/images/add-user.svg');
    this.registerIcon(MatIconEnum.UNLINK, '../../../../assets/images/unlink.svg');

    this.registerIcon(MatIconEnum.SEARCH, '../../../../assets/images/search-icon.svg');
    this.registerIcon(MatIconEnum.PROMOTIONS, '../../../../assets/images/discount-tag.svg');
    // this.registerIcon(MatIconEnum.HAGGLEREWARDS, '../../../../assets/images/haggleLogoInWhite.svg');
    this.registerIcon(MatIconEnum.SETTINGS, "../../../../assets/images/settings.svg");
    this.registerIcon(MatIconEnum.STAR, "../../../../assets/images/star.svg");
  }



  private registerIcon(name: string, path: string): void {
    this._iconRegistry.addSvgIcon(
      name,
      this._sanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }
}

import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {VendorStatusGuardService} from './core/services/guards/vendor-status-guard.service';
import {CertificationRequestComponent} from './certification-request/certification-request.component';
import {VideoConstructorComponent} from './video-constructor/video-constructor.component';
import {RegistrationGuard} from './registration/shared/services/guards/registration-guard.service';
import { ProductSettingsComponent } from './product/product-settings/product-settings.component';
import  {EmailVerificationComponent} from './email-verification/email-verification.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [VendorStatusGuardService],
    pathMatch: 'full',
    loadChildren: './not-found/not-found.module#NotFoundModule'
  },
  {
    path: 'registration',
    loadChildren: './registration/registration.module#RegistrationModule',
    canActivate: [RegistrationGuard],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'registration/:token',
    loadChildren: './registration/registration.module#RegistrationModule',
    canActivate: [RegistrationGuard],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'product',
    loadChildren: './add-product/add-product.module#AddProductModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'products',
    loadChildren: './product/product.module#ProductModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'promotions',
    loadChildren: './promotions/promotions.module#PromotionsModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'promotions/:tab',
    loadChildren: './promotions/promotions.module#PromotionsModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  // {
  //   path: 'haggle_rewards',
  //   loadChildren: './haggle-rewards/haggle-rewards.module#HaggleRewardsModule',
  //   canActivate: [VendorStatusGuardService],
  //   data: {
  //     isShowMenu: true
  //   }
  // },
  // {
  //   path: 'haggle_rewards/:tab',
  //   loadChildren: './haggle-rewards/haggle-rewards.module#HaggleRewardsModule',
  //   canActivate: [VendorStatusGuardService],
  //   data: {
  //     isShowMenu: true
  //   }
  // },
  {
    path: 'services',
    loadChildren: './services/services.module#ServicesModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'change_log',
    loadChildren: './change-log/change-log.module#ChangeLogModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'orders',
    loadChildren: './orders/orders.module#OrdersModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
  {
    path: 'profile/:tab',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [VendorStatusGuardService],
    data: {
      isShowMenu: true
    }
  },
 {
  path: 'settings',
  component: ProductSettingsComponent,
  canActivate: [VendorStatusGuardService],
  data: {
    isShowMenu: true
  }
},
  {
    path: 'embed_code',
    loadChildren: './embed-code/embed-code.module#EmbedCodeModule',
    canActivate: [
      VendorStatusGuardService
    ],
    data: {
      isShowMenu: true
    }
  },
  // {
  //   path: 'help_center',
  //   loadChildren: './help-center/help-center.module#HelpCenterModule',
  //   canActivate: [
  //     VendorStatusGuardService
  //   ],
  //   data: {
  //     isShowMenu: true
  //   }
  // },
  {
    path: 'video_converter',
    component: VideoConstructorComponent,
    data: {
      isShowMenu: false
    }
  },
  {
    path: 'access_request/:token/:status',
    component: CertificationRequestComponent,
    data: {
      isShowMenu: false
    }
  },
  {
    path: 'verify',
    component: EmailVerificationComponent,
    data: {
      isShowMenu: false
    }
  },
  {
    path: 'oops',
    loadChildren: './not-found/not-found.module#NotFoundModule',
    data: {
      isShowMenu: false
    }
  },
  {
    path: '**',
    redirectTo: '/oops/404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SideMenuItem} from '../models/navigation/side-menu-item';
import {UserPermission} from '../models/user/user-permissions.enum';
import {MatIconEnum} from '../models/common/mat-icon.enum';
import {TourTypes} from '../../site-tour/models/tour-types';


@Injectable()
export class MainNavigationService {
  isShowMenu = new BehaviorSubject(true);
  isMenuOpened = new BehaviorSubject(true);

  private readonly _mainMenuItems: SideMenuItem[] = [
    new SideMenuItem(
      [UserPermission.DASHBOARD],
      'Dashboard',
      '/dashboard',
      MatIconEnum.DASHBOARD
    ),
    new SideMenuItem(
      [UserPermission.ORDERS],
      'Orders',
      '/orders',
      MatIconEnum.ORDERS
    ),
    new SideMenuItem(
      [UserPermission.PRODUCTS],
      'Products',
      '/products',
      MatIconEnum.PRODUCTS,
      [
        TourTypes.ADD_PRODUCT + '0',
        TourTypes.ARCHIVE_RESTORE_PRODUCT + '0',
        TourTypes.GO_LIVE + '0',
        TourTypes.NUTRITIONAL_LABEL + '0',
        TourTypes.ACTIVATE_PRODUCTS + '0',
        TourTypes.ADD_PRODUCT_RESTRICTIONS + '0',
        TourTypes.BULK_EDIT + '0',
        TourTypes.EDIT_NUTRITIONAL + '0'
      ]
    ),
    new SideMenuItem(
      [UserPermission.USERS],
      'Users',
      '/users',
      MatIconEnum.USERS,
      TourTypes.ADD_USER + '0'
    ),
    new SideMenuItem(
      [UserPermission.PROFILE],
      'Company Profile',
      '/profile',
      MatIconEnum.PROFILE,
      [
        TourTypes.ADD_PAYMENT_CARD + '0',
        TourTypes.ADD_FOB_LOCATION + '0'
      ].join(' ')
    ),
//     new SideMenuItem(
//       UserPermission.SERVICES,
//       'Services',
//       '/services',
//       MatIconEnum.SERVICES
//     ),
    new SideMenuItem(
      [UserPermission.PRODUCTS, UserPermission.DASHBOARD],
      'Epallet eCODE',
      '/embed_code',
      MatIconEnum.EMBED_CODE,
      TourTypes.EMBED_CODE + '0'
    ),
    // new SideMenuItem(
    //   UserPermission.DASHBOARD,
    //   'Epallet eCode',
    //   '/embed_code',
    //   MatIconEnum.EMBED_CODE,
    //   TourTypes.EMBED_CODE + '0'
    // ),
    new SideMenuItem(
      [UserPermission.PRODUCTS],
      'Promotions',
      '/promotions',
      MatIconEnum.PROMOTIONS,
      [
        TourTypes.ADD_COUPON + '0',
      ].join(' ')
    ),
    // new SideMenuItem(
    //   [UserPermission.PRODUCTS],
    //   'Haggle Rewards',
    //   '/haggle_rewards',
    //   MatIconEnum.HAGGLEREWARDS,
    // ),
    new SideMenuItem(
      [UserPermission.PRODUCTS],
      'Settings',
      '/settings',
      MatIconEnum.SETTINGS,
    ),
  ];



  openMenu(): void {
    this.isMenuOpened.next(true);
  }

  closeMenu(): void {
    this.isMenuOpened.next(false);
  }

  createMenuForCurrentUser(permissions: UserPermission[]): SideMenuItem[] {
    return this._mainMenuItems.filter(menuItem => permissions.some(ai => menuItem.items.includes(ai)));
  }

}

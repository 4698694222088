import {ProductMedia} from './product-media/product-media';
import {Id} from '../types';
import {Country} from '../common/country';
import {Type} from 'class-transformer';
import {decoratorType, applyMixins} from 'lib-shared';
import {ProductItemAbstract} from './product-item.abstract';
import {ShippingStorageMethod} from './enums/shipping-storage-method.enum';
import {LeadTimeUom} from './enums/lead-time-uom.enum';
import {ShippingStorageTemp} from './enums/shipping-storage-temp.enum';
import {ProductNutrition} from './nutrition/product-nutrition';
import {CaseSizeUom} from './enums/case-size-uom.enum';
import {CaseWeightUom} from './enums/case-weight-uom.enum';
import {UnitSizeUom} from './enums/unit-size-uom.enum';
import {IndustryType} from './enums/industry-type.enum';
import {DateCodeType} from './enums/date-code-type.enum';
import {FreightClass} from './enums/freight-class.enum';
import {CommonProductCategory} from '../common/common-category';
import {ProductPromo} from './product-promo';
import {Restrictive} from './restrictive';
import {RestrictiveWithSelectionForm} from '../../modules/forms/restrictive-with-selection-form/models/restrictive-with-selection.form';


class Product extends ProductItemAbstract implements RestrictiveWithSelectionForm {
  food = true;

  visible_to_customer_emails: string[] = [];

  // Restrictive
//   for_customer_groups: Id[] = [];
  for_customer_types: Id[] = [];
//   for_customer_groups_only_to_selected = true;
  for_customer_types_only_to_selected = true;
  for_states: string[] = [];
  for_zip_from = '';
  for_zip_to = '';
  hasRestrictions: boolean;
//   hasCustomerGroupsRestrictions: boolean;
  hasCustomerTypesRestrictions: boolean;
  hasZipCodeRestrictions: boolean;
  hasStatesRestrictions: boolean;

  cool_school_point_eligible: boolean = null;
  categories: Id[] = [];

  gtin = '';
  description_short = '';
  description_long = '';
  mfg_name = '';
  mfg_item = '';
  brand = '';

  vendor_name = '';
  vendor_item = '';
  packaging = '';

  promo: number[] = [];
  // haggle_rewards: number[] = [];

  industry_type: IndustryType[] = [];
  date_code_type: DateCodeType | '' = '';

  shelf_life = '';
  minimum_lifespan_from_arrival = '';
  minimum_lifespan_from_production = '';

  country_of_manufacturer: Country['country_code'][] = [];
  country_of_growth: Country['country_code'][] = [];
  // specsheet_access_request = false;
  charge_redemption_value = false;
  for_mixed_pallet = false;
  is_prototype = false;
  contains_cbd = false;

  shipping_method: ShippingStorageMethod | '' = '';
  shipping_temp_ambient = false;
  shipping_temp_min: number = null;
  shipping_temp_max: number = null;
  shipping_temp_uom: ShippingStorageTemp = ShippingStorageTemp.FAHRENHEIT;
  storage_method: ShippingStorageMethod | '' = '';
  storage_temp_ambient = false;
  storage_temp_min: number = null;
  storage_temp_max: number = null;
  storage_temp_uom: ShippingStorageTemp = ShippingStorageTemp.FAHRENHEIT;
  freight_class: FreightClass | '' = '';
  auto_calculate_freight_class: boolean;

  outer_pack: number = null;
  inner_pack: number = null;
  unit_size = null;
  unit_size_uom: UnitSizeUom = UnitSizeUom.KG;
  readonly pack_size = '';
  readonly units_per_case: number = null;
  case_weight_gross: number = null;
  case_weight_net: number = null;
  case_weight_tare: number = null;
  case_weight_uom: CaseWeightUom = CaseWeightUom.LBS;
  case_length: number = null;
  case_width: number = null;
  case_height: number = null;
  case_size_uom: CaseSizeUom = CaseSizeUom.INCH;
  case_cubic_ft: number = null;
  case_cubic_meter: number = null;
  case_lb_cubic_ft: number = null;
  case_kg_cubic_meter: number = null;
  case_upc = '';
  case_gtin = '';
  case_date_code_type = '';

  ti: number = null;
  hi: number = null;
  ti_last: number = null;
  case_per_pallet: number = null;
  pallet_length: number = null;
  pallet_width: number = null;
  pallet_height: number = null;
  pallet_per_truckload: number = null;
  total_pallet_weight: number = null;
  weight_per_truckload: number = null;
  double_stackable = false;
  triple_stackable = false;
  sold_in_stack = false;

  certificates: Id[] = [];

  off_invoice;

  @Type(decoratorType(ProductMedia))
  images: ProductMedia[] = [];

  @Type(decoratorType(ProductMedia))
  videos: ProductMedia[] = [];

  @Type(decoratorType(ProductNutrition))
  nutritions: ProductNutrition[] = [new ProductNutrition()];



  get isUnitSizeVarious(): boolean {
    return this.unit_size_uom === UnitSizeUom.VARIOUS;
  }



  formatProduct(categories: CommonProductCategory[]): void {
    this._formatCategories(categories);

    this._filterCountriesFields();

    this._formatProductAggregationState();
    this._formatProductRedemptionValue();
    this._formatUnitSize();

    this._formatShippingStorageTemperatures();
  }



  private _filterCountriesFields(): void {
    this.country_of_manufacturer = this.country_of_manufacturer.filter(country => country !== '');
    this.country_of_growth = this.country_of_growth.filter(country => country !== '');
  }

  private _formatProductAggregationState(): void {
    if(!this.food) {
      this.catch_weight = false;
    }
  }

  private _formatUnitSize(): void {
    if(this.isUnitSizeVarious) {
      this.unit_size = null;
    }
  }

  private _formatProductRedemptionValue(): void {
    if(this.isProductLoose) {
      this.charge_redemption_value = false;
    }
  }

  private _formatShippingStorageTemperatures(): void {
    if(this.shipping_temp_ambient) {
      this.shipping_temp_min = null;
      this.shipping_temp_max = null;
    }
    if(this.storage_temp_ambient) {
      this.storage_temp_min = null;
      this.storage_temp_max = null;
    }
  }

  private _formatCategories(categories: CommonProductCategory[]): void {
    const allSubcategories =  categories.reduce((ids, category) => ids.concat(category.children.map(subcategory => subcategory.id)), []);
    this.categories = this.categories.filter(subcat => allSubcategories.includes(subcat));
  }
}

applyMixins(Product, [Restrictive]);

export {Product};
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';

import * as Sentry from "@sentry/angular";
// import { Integrations as TracingIntegrations } from '@sentry/tracing';


if(environment.enableSentry) {
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.sentryEnv,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      // Sentry.browserTracingIntegration(),
      // new TracingIntegrations.BrowserTracing({
      //   tracingOrigins: ['localhost', /^\//],
      //   routingInstrumentation: Sentry.instrumentAngularRouting,
      // }),

      // Registers the Replay integration,
      // which automatically captures Session Replays
      // Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
  
}


if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module[ 'hot' ]) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('Ammm.. HMR is not enabled for webpack');
  }
} else {
  bootstrap()
    .then(() => {
      const splashScreen = document.getElementById('app-preloader');
      splashScreen.setAttribute('class', 'fade-out');
      setTimeout(() =>  splashScreen.remove(), 900);
    })
    .catch(err => console.log(err));
}

import {ProductItemAbstract} from '../product-item.abstract';
import {UrlPath} from '../../types';
import {StockRecordStatus} from '../product-status';
import {ProductPromo} from '../product-promo';
import {decoratorType} from 'lib-shared';
import {Type} from 'class-transformer';
import {ProductPromoMin} from '../product-promo-min.model';
// import { ProductHaggleRewards } from '../product-haggle-rewards';
// import { ProductHaggleMin } from '../product-media/product-haggle-min.model';

export class ProductListItem extends ProductItemAbstract {
  thumbnail: UrlPath;
  price_avg: number;
  total_quantity: number;
  archived: boolean;
  food: boolean;
  special_price_cnt: number;

  showTourData: boolean = false;

  @Type(decoratorType(ProductPromoMin))
  promo: ProductPromo[] = [];

  // @Type(decoratorType(ProductHaggleMin))
  // vendor_funded_hr: ProductHaggleRewards[] = [];

  get totalQuantity(): string {
    const qty = this.total_quantity || '-';
    const hasInfinity = this.stock_records.some(record => record.has_infinite_quantity && record.status == StockRecordStatus.ON)
      ? '(∞)'
      : '';

    return `${qty} ${hasInfinity}`;
  }

  get productName(): string {
    if( this.name && this.mfg_item ) return `${this.mfg_item} - ${this.name}`;
    if( this.name && this.upc ) return `${this.upc} - ${this.name}`;
    if( this.name ) return this.name;
    return `Product (${this.id})`;
  }

  get productPromoList(): string {
    return this.promo.map( ({name}) => name ).join(', ');
  }
  // get productHaggleList(): string{
  //   return this.vendor_funded_hr.map( ({amount_with_type}) => amount_with_type).join(', ');
  // }

}

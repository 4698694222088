export enum MatIconEnum {
  STORE = 'store',
  LOGOUT = 'logout',
  PENCIL = 'pencil',
  BIN = 'bin',
  INFINITY = 'infinity',
  PLAY = 'play',
  PAUSE = 'pause',
  DOWNLOAD = 'download',
  CROSS = 'cross',
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  DRAG_AND_DROP = 'dnd',
  DASHBOARD = 'dashboard',
  ORDERS = 'orders',
  PRODUCTS = 'products',
  USERS = 'users',
  PROFILE = 'profile',
  EMBED_CODE = 'embed_code',
  EPALLET = 'epallet',
  EPALLET_LOGO = 'epallet_logo',
  EPALLET_BLACK_LOGO = 'epallet_black_logo',
  RESTORE = 'restore',
  EYE = 'eye',
  ELLIPSIS = 'ellipsis',
  DELETE = 'delete',
  ARROW_RIGHT = 'arrow_right',
  ARROW_RIGHT_SMALL = 'arrow_right_samll',
  INFORMATION = 'information',
  TICK = 'tick',
  ADD = 'add',
  REMOVE = 'remove',
  SERVICES = 'services',
  CHANGE_LOG = 'CHANGE_LOG',
  FILTER = 'filter',
  ADD_USER = 'add-user',
  UNLINK = 'unlink',
  SEARCH = 'search',
  PROMOTIONS = 'promotions',
  SETTINGS = 'settings',
  // HAGGLEREWARDS = 'haggle_rewards',
  STAR = 'star',
}
